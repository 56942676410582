interface ReplaceValue {
    [key: string]: string|number;
}

export function __(key: string, replace: ReplaceValue = {}, locale: string = window.__locale) {

    let translation = key;

    try {
        translation = key.split('.').reduce((t, i) => t[i] || null, window.__translations[locale]);
    } catch (_) {
        console.warn(`Translation not found: "${key}"`);
    }

    for (const [key, value] of Object.entries(replace)) {
        translation = translation.replaceAll(':' + key, value.toString());
    };

    return translation;
}
