import Vue from "vue";

declare module "vue/types/vue.d.ts" {
    interface Vue {
        appEndpoint: (endpoint: string) => string;
        appEndpointLandlord: (endpoint: string) => string;
        redirectTo: (href: string) => void;
        mobileAppEndpoint: (endpoint: string) => string;
    }
}

const globalHelpersMixin = Vue.mixin({
    methods: {
        appEndpoint(endpoint: string): string {
            if (
                "appEndpoint" in window &&
                typeof window.appEndpoint === "string"
            ) {
                return window.appEndpoint + endpoint;
            }
            throw "No API endpoint was configured!";
        },

        appEndpointLandlord(endpoint: string): string {
            if (
                "appEndpointLandlord" in window &&
                typeof window.appEndpointLandlord === "string"
            ) {
                return window.appEndpointLandlord + endpoint;
            }
            throw "No API endpoint was configured!";
        },

        redirectTo(href: string): void {
            window.location.href = href;
        },

        mobileAppEndpoint(endpoint: string): string {
            if (
                "appMobileEnvType" in window &&
                typeof window.appMobileEndpoint === "string"
            ) {
                if (
                    window.appMobileEnvType === "dev" ||
                    window.appMobileEnvType === "local"
                )
                    return window.appMobileEndpoint + endpoint;
                else return endpoint;
            }

            throw "No API endpoint was configured!";
        },
    },
});

export default globalHelpersMixin;
